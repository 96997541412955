import React, { useState } from "react"
import { css } from "@emotion/react"
import SEO from "../../components/bseo"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"
import BookLayout from "../../components/BookLayout"
import BookNav from "../../components/BookNav"
import BookListItem from "../../components/BookListItem"
import axios from "axios"

const BookRequest = () => {
  const [name, setName] = useState("")
  const [url, setUrl] = useState("")
  const [email, setEmail] = useState("")
  const [requested, setRequested] = useState(false)

  const onClickRequestBook = () => {
    if (name && email) {
      axios
        .post("https://slashblogs.com/request", {
          name,
          url,
          email,
        })
        .then(function (res) {
          setName("")
          setUrl("")
          setEmail("")
          setRequested(true)
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  return (
    <BookLayout>
      <SEO title="Request Book"></SEO>
      <h1
        css={css`
          text-align: center;
          margin-top: 20px !important;
        `}
      >
        Request A Book
      </h1>
      <div
        css={css`
          max-width: 800px;
          margin: 30px auto;
          padding: 0 50px;
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          text-align: center;

          img {
            width: 80%;
          }

          a {
            all: unset;
          }

          label {
            margin: 20px 0;
          }

          input {
            padding: 10px;
            font-size: 18px;
          }

          input::placeholder {
            color: #c8c8c8;
          }
        `}
      >
        <div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <label htmlFor="bookname">Book Name </label>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              id="bookname"
              type="text"
              placeholder="The Alchemist"
            ></input>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <label htmlFor="bookurl">Book URL </label>
            <input
              value={url}
              onChange={e => setUrl(e.target.value)}
              id="bookurl"
              type="text"
              placeholder="https://www.goodreads.com/book/show/18144590-the-alchemist"
            ></input>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <label htmlFor="email">
              Your Email (We'll let you know when we have the book)
            </label>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              id="email"
              type="email"
              placeholder="santiago@gmail.com"
            ></input>
          </div>
          <button
            css={css`
              background: #000;
              color: #fafafa;
              border: 0;
              padding: 10px;
              font-size: 18px;
              cursor: pointer;
              margin-top: 20px;
            `}
            onClick={onClickRequestBook}
          >
            Request Book
          </button>
          {requested && <h2>Request Sent!</h2>}
        </div>
      </div>
    </BookLayout>
  )
}

export default BookRequest
